import { notDesktop, styled } from "@obvio/app";
import { RichText } from "@obvio/template";
import { Grid, Stack } from "@obvio/ui";

import { FloatImage } from "./FloatImage";

import type { ImageAsset } from "@obvio/app";
import type { ReactElement } from "react";

export const ImgWrapHigh = styled.div`
  width: 100%;
  aspect-ratio: 1/ 1.36;
`;

export const ImgWrapLow = styled.div`
  width: 100%;
  aspect-ratio: 1;
`;

const ImgWrapFull = styled.div`
  grid-row: span 2;
  width: 100%;
  aspect-ratio: 1 / 1.25;
`;

export const ImagesWrap = styled.div`
  display: flex;
  width: 100%;
  > div {
    display: inline-block;
  }
  > div:first-child {
    width: 50%;
    margin-right: ${(theme) => theme.spacing.medium};
  }
  > div:last-child {
    width: 40%;
  }
`;

const Top = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`;

const Wrap = styled(Stack)`
  h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
`;

const Bottom = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`;

type FullSectionProps = {
  image: ImageAsset[];
  image2: ImageAsset[];
  images: ImageAsset[];
  text1: string;
  text2: string;
  text3: string;
};

export function FullSection({
  image,
  image2,
  images,
  text1,
  text2,
  text3,
}: FullSectionProps): ReactElement {
  return (
    <Wrap kind="vertical" spacing="10rem">
      <Top templateRows="1fr auto" templateColumns="50% 50%" gap="medium">
        <ImagesWrap>
          <div>
            <ImgWrapHigh>
              <FloatImage img={image[0]} />
            </ImgWrapHigh>
          </div>
          <div>
            <ImgWrapLow>
              <FloatImage img={image[1]} />
            </ImgWrapLow>
          </div>
        </ImagesWrap>
        <ImgWrapFull>
          <FloatImage img={image2[0]} />
        </ImgWrapFull>
        <div>
          <RichText value={text1} />
        </div>
      </Top>
      <Bottom templateRows="1fr auto" templateColumns="50% 50%" gap="medium">
        <div style={{ width: "50%", marginLeft: "auto" }}>
          <ImgWrapLow>
            <FloatImage img={images[0]} />
          </ImgWrapLow>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "50%" }}>
            <ImgWrapHigh>
              <FloatImage img={images[1]} />
            </ImgWrapHigh>
          </div>
          <div style={{ width: "30%" }}>
            <ImgWrapLow>
              <FloatImage img={images[2]} />
            </ImgWrapLow>
          </div>
        </div>
        <div>
          <RichText value={text2} />
        </div>
        <div>
          <RichText value={text3} />
        </div>
      </Bottom>
    </Wrap>
  );
}
